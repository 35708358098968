.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.btn {
  padding: 0.65em 1em;
}
.priceTick {
  font-size: 24px;
  color: #f4590b;
}

.btn-primary {
  background-color: #083e9b;
  border-color: #083e9b;
}
.price-cycle {
  color: #f4590b;
}
.comingSoonText {
  color: #f4590b;
}
.pricing-feature-list {
  text-align: left;
}
.priceTextWp {
  font-size: 18px;
}
.priceTextVps {
  font-size: 18px;
  text-decoration: line-through;
}
.TextPriceCOllege {
  font-weight: bold;
}
.tomcatprice {
  font-size: 12px;
  color: #f4590b;
}

/* .linuxBgImage {
  background: url("public/assets/img/hero-offer-bg.svg") no-repeat center center /
    cover;
  opacity: 1;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
